<template>
  <base-info-card title="Legal">
    <a
      v-for="feature in features"
      :key="feature.text"
      :text="feature.text"
      class="mb-8 grey--text text-body-1 d-block text-none"
      :href="feature.url"
      v-html="`&rsaquo; ${feature.text}`"
    />
    <div>COPYRIGHT © 2021</div>
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    data: () => ({
      features: [
        { text: 'Privacy Policy', url: 'privacy' },
        { text: 'Cookie Policy', url: 'cookies' },
      ],
    }),
  }
</script>
